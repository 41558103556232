.svelte-json-editor {
    /* define a custom theme color */
    --jse-theme-color: #039BE5;
    --jse-theme-color-highlight: #6bc9f7;
    font-weight: normal;
    flex: 1;
  }
  
.svelte-json-editor .jse-main {
  min-height: 110px;
}