.root-role-template-preview {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.root-role-template-preview .copy-indicator {
  display: none;
  position: absolute;
  top: 5px;
  right: 5px;
}

.root-role-template-preview:hover .copy-indicator {
  display: block;
}